import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function useScrollToAnchor() {
  const { hash, pathname } = useLocation();

  useEffect(() => {
    const element = hash && document.getElementById(hash.substring(1));
    if (element) {
      element.scrollIntoView({behavior: "smooth"});
    } else {
      window.scroll(0, 0);
    }
  }, [hash, pathname]);
}