import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const useGoogleTracking = () => {
  const trackingId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  const location = useLocation();
  useEffect(() => {
    if (!trackingId) {
      throw new Error(
        'Please provide REACT_APP_GA_MEASUREMENT_ID as environmental variable'
      );
    } else {
      window.gtag('config', trackingId, {
        page_path: location.pathname,
      });
    }
  }, [trackingId, location]);
};
