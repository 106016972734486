import React from 'react';
import PropTypes from 'prop-types';
import styles from './MobileNavButton.module.css';
import { useLocation } from 'react-router-dom';
import { useContext } from 'react';
import { BrowserRoutes } from '../../constants/BrowserRoutes';
import ThemeContext from '../../contexts/ThemeContext';

const MobileNavButton = ({ open, handleToggle }) => {
  const theme = useContext(ThemeContext);
  const { pathname } = useLocation();
  const backgroundColor =
    pathname === BrowserRoutes.HOME ? theme.whiteColor : theme.blackColor;

  const PattyDiv = () => <div style={{ backgroundColor }}></div>;
  const navButtonClasses = `${styles.MobileNavButton} ${
    open ? styles.open : ''
  }`;
  return (
    <div
      onClick={handleToggle}
      className={navButtonClasses}
      data-testid="MobileNavButton"
    >
      <PattyDiv />
      <PattyDiv />
      <PattyDiv />
    </div>
  );
};

MobileNavButton.propTypes = {
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

MobileNavButton.defaultProps = {
  handleToggle: undefined,
  open: undefined,
};

export default MobileNavButton;
